<template>
  <div>
    <div
      v-if="!claimed"
      class="ma-4"
    >
      <TaskBlocker :assignee="data.assignee" />
    </div>

    <v-progress-linear
      v-if="taskDataLoading"
      indeterminate
      color="primary darken-2"
    ></v-progress-linear>

    <div v-else>
      <p>
        Das Projekt <span>{{ project.projektId }} - {{ project.name }}</span> ist in den Status
        <b :style="{ color: project.projectStatus.color }">{{ project.projectStatus.description }}</b> übergegangen.
      </p>
      <p>
        Mit dem bestätigen dieser Aufgabe wird der Vertriebsstaus auf
        <b :style="{ color: nextDistributionStatus.color }">{{ nextDistributionStatus.description }}</b> gesetzt.
      </p>
      <v-expansion-panels
        :value="0"
        class="mb-6 mt-2"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <span>Projekt Details </span>
              <span v-if="variables && variables.projectId">{{ variables.projectId }}</span>
              <span v-if="project && project.name"> - {{ project.name }}</span></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ProjectDetails
              :project="project"
              hideCleverReachButton
              hideBrevoButton
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-alert
        v-if="!isCurrentProjectStatusValid"
        elevation="2"
        type="error"
        border="left"
        class="my-2"
        outlined
        prominent
      >
        <span>Das Projekt <b>{{ project.projektId }} - {{ project.name }}</b> hat nicht den erwarteten Projekt Status.</span><br />
        <span>
          Aktueller Projekt Status <b :style="{color: project.projectStatus.color}">{{ project.projectStatus.description }}</b>,
          erwartet wird <b :style="{color: expectedProjectStatus.color}">{{ expectedProjectStatus.description }}</b>!
        </span><br />
        <span><v-icon color="blue">mdi-information-variant</v-icon> Die Aufgabe kann nicht bearbeitet werden, bis der Status dem erwarteten Status entspricht</span>
      </v-alert>
    </div>

    <div class="mb-4 align-buttons-right">
      <v-btn
        :disabled="!isEditable"
        :loading="isSubmitting"
        color="primary"
        @click="completeTaskDialog = true"
      >
        Aufgabe bestätigen
      </v-btn>
    </div>

    <v-dialog
      v-if="isEditable"
      v-model="completeTaskDialog"
      width="40vw"
    >
      <v-card>
        <v-card-title>
          Aufgabe bestätigen
        </v-card-title>
        <v-card-text>
          <p>
            Mit der Bestätigung der Aufgabe wir der Vertriebsstaus für Projekt <span>{{ project.projektId }} - {{ project.name }}</span> von
            <b :style="{ color: project.distributionStatus.color }">{{ project.distributionStatus.description }}</b> auf
            <b :style="{ color: nextDistributionStatus.color }">{{ nextDistributionStatus.description }}</b> geändert.
          </p>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn
            :disabled="isSubmitting"
            small
            color="secondary"
            class="align-center"
            @click="completeTaskDialog = !completeTaskDialog"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!isEditable"
            :loading="isSubmitting"
            small
            color="primary"
            class="align-center"
            @click="completeTask"
          >
            Aufgabe bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NotificationObject from '@/main/NotificationObject.js';
import { HTTP } from '@/main/httpClient.js';
import TaskBlocker from '@/task/TaskBlocker.vue';
import ProjectDetails from '@/components/constructionProjects/ProjectDetails.vue';

const EXPECTED_PROJECT_STATUS = 'project-construction-started';
const NEXT_DISTRIBUTION_STATUS = 'built-project';

export default {
  name: 'ActionAktiveNachvermarktung',
  components: {
    TaskBlocker,
    ProjectDetails
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskDataLoading: true,
    isSubmitting: false,
    completeTaskDialog: false,
    variables: {},
    project: null,
    nextDistributionStatus: null,
    expectedProjectStatus: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isCurrentProjectStatusValid() {
      if (!this.project || !this.expectedProjectStatus) return false;
      return (
        this.project.projectStatus.name === this.expectedProjectStatus.name
      );
    },
    isCamundaAdmin() {
      return this.$auth
        .user()
        .userRoles.includes('camunda-admin', 'LgSpotAdmin');
    },
    isEditable() {
      return (
        this.claimed &&
        !this.taskDataLoading &&
        (this.isCurrentProjectStatusValid || this.isCamundaAdmin)
      );
    }
  },
  mounted: async function () {
    this.taskDataLoading = true;
    // fix race condition after reopen the same task
    await new Promise((resolve) => {
      setTimeout(resolve, 1);
    });

    const promises = [
      this.data.variables.then((variables) => {
        this.variables = Object.keys(variables).reduce((obj, key) => {
          obj[key] = variables[key].value;
          return obj;
        }, {});

        return HTTP.get(
          '/past/projects?where[projektId][equals]=' + this.variables.projectId
        ).then((response) => {
          this.project = response.data[0];
        });
      }),
      HTTP.get(
        '/past/distributionStatuses?where[name][equals]=' +
          NEXT_DISTRIBUTION_STATUS
      ).then(({ data }) => {
        if (!data || data.length !== 1) {
          throw new Error(
            `Distribution Status ${NEXT_DISTRIBUTION_STATUS} not found`
          );
        }
        this.nextDistributionStatus = data[0];
      }),
      HTTP.get(
        '/past/projectStatuses?where[name][equals]=' + EXPECTED_PROJECT_STATUS
      ).then(({ data }) => {
        if (!data || data.length !== 1) {
          throw new Error(
            `Projekt Status ${EXPECTED_PROJECT_STATUS} not found`
          );
        }
        this.expectedProjectStatus = data[0];
      })
    ];

    Promise.all(promises)
      .then(() => {
        this.taskDataLoading = false;
      })
      .catch((err) => {
        console.error(err);
        this.showMessage(
          'error',
          'Beim laden der Aufgabe ist ein Fehler aufgetreten'
        );
      });
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    completeTask: function () {
      const taskEditorMap = {
        type: 'Object',
        valueInfo: {
          objectTypeName:
            'java.util.HashMap<java.lang.Object,java.lang.Object>',
          serializationDataFormat: 'application/json'
        }
      };
      taskEditorMap.value = Object.keys(this.variables).includes(
        'taskEditorMap'
      )
        ? this.variables.taskEditorMap
        : {};
      taskEditorMap.value[this.data.taskDefinitionKey] = this.username;
      taskEditorMap.value = JSON.stringify(taskEditorMap.value);

      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {
        variables: { taskEditorMap }
      })
        .then(() => {
          this.showMessage(
            'success',
            'Die Aufgabe wurde erfolgreich geschlossen!'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showMessage(
            'error',
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        );
    }
  }
};
</script>
